import React from 'react';

interface Props {
  fill: string;
  size: number;
}

function TrashIcon(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width="24" height="28" viewBox="0 0 24 28" fill="none">
        <path d="M8 10H10V22H8V10Z" fill={props.fill} />
        <path d="M14 10H16V22H14V10Z" fill={props.fill} />
        <path
          d="M0 4V6H2V26C2 26.5304 2.21071 27.0391 2.58579 27.4142C2.96086 27.7893 3.46957 28 4 28H20C20.5304 28 21.0391 27.7893 21.4142 27.4142C21.7893 27.0391 22 26.5304 22 26V6H24V4H0ZM4 26V6H20V26H4Z"
          fill={props.fill}
        />
        <path d="M8 0H16V2H8V0Z" fill={props.fill} />
      </svg>
    </div>
  );
}

TrashIcon.defaultProps = {
  fill: 'black',
  size: 16,
};

export { TrashIcon };
