import React, { ReactNode, isValidElement } from 'react';

import { isEmptyOrNil } from '../../utils/function-utils';
import { HelpText } from './styles';
import { FileInputProps } from './types';

export function renderHelpText(helpText: FileInputProps['helpText']): ReactNode | string | null {
  if (isEmptyOrNil(helpText)) {
    return null;
  }

  if (typeof helpText === 'string') {
    return <HelpText>{helpText}</HelpText>;
  }

  if (isValidElement(helpText)) {
    return <div style={{ margin: '14px 0' }}>{helpText}</div>;
  }

  throw new Error('FileInput: Invalid render text prop');
}
