import styled from 'styled-components';

import { Colors } from '../../utils/style-utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputFacade = styled.div.attrs({
  className: 'relative cursor-pointer',
})`
  height: 70px;
  background: #fafafa;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 15px;

  ${Container}.inline & {
    background: transparent;
    border: 0 none;
  }

  ${Container}.empty & {
    border: 1px dashed #595e8c;
    border-radius: 4rem;
  }

  ${Container}.error & {
    background: rgba(235, 87, 87, 0.05);
    border: 1px solid #eb5757;
  }
`;

export const InputContent = styled.span`
  position: absolute;
  cursor: pointer;
  font-weight: normal;
  font-family: 'Norse', sans-serif;
  font-size: 15px;
  color: #2f3144;
  left: 62px;
  right: 62px;
  top: 22px;

  ${Container}.inline & {
    color: #2f3144;
  }

  ${Container}.empty & {
    color: ${Colors.Blue500};
  }
`;

export const TextInput = styled.input`
  height: 70px;
  padding: 10px 10px 10px 62px;
`;

export const HelpText = styled.span.attrs({
  className: 'font-normal not-italic',
})`
  font-family: 'Norse', sans-serif;
  font-size: 15px;
  color: #333;
  margin: 14px 0;
`;

export const ButtonContainer = styled.div.attrs({
  className: 'absolute',
})`
  right: 22px;
  top: 20px;
  background: transparent;
  z-index: 10;
`;

export const PlaceholderIcon = styled.div.attrs({
  className: 'absolute',
})`
  left: 22px;
  top: 20px;
  z-index: 10;

  ${Container}.empty & {
    left: 28px;
    top: 23px;
  }
`;
