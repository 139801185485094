import React from 'react';

interface Props {
  fill: string;
  size: number;
}

function FileIcon(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width="30" height="31" viewBox="0 0 30 31" fill="none">
        <path
          d="M25.0371 8.95508L18.7324 2.65039C18.5566 2.47461 18.3193 2.375 18.0703 2.375H5.625C5.10645 2.375 4.6875 2.79395 4.6875 3.3125V27.6875C4.6875 28.2061 5.10645 28.625 5.625 28.625H24.375C24.8936 28.625 25.3125 28.2061 25.3125 27.6875V9.62012C25.3125 9.37109 25.2129 9.13086 25.0371 8.95508ZM23.1504 10.0508H17.6367V4.53711L23.1504 10.0508ZM23.2031 26.5156H6.79688V4.48438H15.6445V10.8125C15.6445 11.1388 15.7742 11.4518 16.0049 11.6826C16.2357 11.9133 16.5487 12.043 16.875 12.043H23.2031V26.5156Z"
          fill={props.fill}
        />
      </svg>
    </div>
  );
}

FileIcon.defaultProps = {
  fill: '#4F4F4F',
  size: 16,
};

export { FileIcon };
