import React from 'react';

import { Colors } from '../utils/style-utils';

interface Props {
  fill: string;
  size: number;
}

function AddFileIcon(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M20.0297 6.76406L14.9859 1.72031C14.8453 1.57969 14.6555 1.5 14.4562 1.5H4.5C4.08516 1.5 3.75 1.83516 3.75 2.25V21.75C3.75 22.1648 4.08516 22.5 4.5 22.5H19.5C19.9148 22.5 20.25 22.1648 20.25 21.75V7.29609C20.25 7.09687 20.1703 6.90469 20.0297 6.76406ZM18.5203 7.64062H14.1094V3.22969L18.5203 7.64062ZM18.5625 20.8125H5.4375V3.1875H12.5156V8.25C12.5156 8.51107 12.6193 8.76145 12.8039 8.94606C12.9885 9.13066 13.2389 9.23438 13.5 9.23438H18.5625V20.8125ZM12.75 11.0625C12.75 10.9594 12.6656 10.875 12.5625 10.875H11.4375C11.3344 10.875 11.25 10.9594 11.25 11.0625V13.5938H8.71875C8.61562 13.5938 8.53125 13.6781 8.53125 13.7812V14.9062C8.53125 15.0094 8.61562 15.0938 8.71875 15.0938H11.25V17.625C11.25 17.7281 11.3344 17.8125 11.4375 17.8125H12.5625C12.6656 17.8125 12.75 17.7281 12.75 17.625V15.0938H15.2812C15.3844 15.0938 15.4688 15.0094 15.4688 14.9062V13.7812C15.4688 13.6781 15.3844 13.5938 15.2812 13.5938H12.75V11.0625Z"
          fill={props.fill}
        />
      </svg>
    </div>
  );
}

AddFileIcon.defaultProps = {
  fill: Colors.Blue500,
  size: 16,
};

export { AddFileIcon };
