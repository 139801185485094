import React, { ChangeEvent, FC, InputHTMLAttributes, ReactElement, useRef, useState } from 'react';
import styled from 'styled-components';

import CheckedImage from '../images/checked.png';

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  defaultChecked?: boolean;
  onChange?: (x: boolean) => void;
  label?: ReactElement | string;
}

const Box = styled.div.attrs({ className: 'relative' })`
  background: white;
  border: 1.15625px solid #b7bad6;
  width: 14px;
  height: 14px;
  border-radius: 3px;
`;

const Label = styled.span.attrs({
  className: 'font-normal not-italic',
})`
  font-family: 'Norse', sans-serif;
  font-size: 11px;
  color: #4f4f4f;
  margin: 0 14px;
`;

export const Checkbox: FC<CheckboxProps> = ({ defaultChecked, onChange, label, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(Boolean(defaultChecked));

  return (
    <div className="flex flex-row items-center">
      <Box onClick={() => ref.current?.click()}>
        {checked && <img src={CheckedImage} style={{ position: 'absolute', top: 2, left: 2 }} />}

        <input
          ref={ref}
          type="checkbox"
          className="invisible"
          checked={checked}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setChecked(event.target.checked);
            onChange && onChange(event.target.checked);
          }}
          {...rest}
        />
      </Box>

      {label && <Label>{label}</Label>}
    </div>
  );
};
