import { includes, toLower } from 'ramda';
import React, { FC, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiInfo } from 'react-icons/fi';
import styled from 'styled-components';

import { Colors } from '../utils/style-utils';

interface P {
  onDrop: (files: FileList) => void;
}

const DropArea = styled.div`
  border-color: ${Colors.Blue500};
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropPlaceholder = styled.div`
  color: ${Colors.Blue900};
`;

function checkFilesValidity(files: FileList) {
  let valid = true;

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const [extension] = file.name.split('.').reverse();
    if (!includes(toLower(extension), ['pdf', 'doc', 'docx'])) {
      valid = false;
      break;
    }
  }

  return valid;
}

export const DragAndDrop: FC<P> = (props) => {
  const [dragging, setDrag] = useState(false);
  const counter = useRef<number>(0);
  const dropRef = useRef<HTMLDivElement>(null);

  function handleDrag(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  function handleDragIn(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    counter.current += 1;

    if (event.dataTransfer?.items && event.dataTransfer?.items.length > 0) {
      setDrag(true);
    }
  }

  function handleDragOut(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    counter.current -= 1;

    if (counter.current === 0) {
      setDrag(false);
    }
  }

  function handleDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDrag(false);
    const iconProps = { color: Colors.Blue900, className: 'mr-2' };

    if (event.dataTransfer?.files && event.dataTransfer?.files.length > 0) {
      if (event.dataTransfer.files.length > 1) {
        toast(
          <span className="flex items-center">
            <FiInfo {...iconProps} />
            Please drop only one file
          </span>,
        );
      }

      if (!checkFilesValidity(event.dataTransfer.files)) {
        toast(
          <span className="flex items-center">
            <FiInfo {...iconProps} />
            One or more files are not valid. Accepted extensions: PDF, DOC, DOCX
          </span>,
        );
        return;
      }

      props.onDrop(event.dataTransfer.files);
      event.dataTransfer.clearData();
      counter.current = 0;
    }
  }

  useEffect(() => {
    const div = dropRef.current as HTMLDivElement | null;
    div?.addEventListener('dragenter', handleDragIn);
    div?.addEventListener('dragleave', handleDragOut);
    div?.addEventListener('dragover', handleDrag);
    div?.addEventListener('drop', handleDrop);

    return () => {
      const div = dropRef.current as HTMLDivElement | null;
      div?.removeEventListener('dragenter', handleDragIn);
      div?.removeEventListener('dragleave', handleDragOut);
      div?.removeEventListener('dragover', handleDrag);
      div?.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <div className="inline-block relative w-full" ref={dropRef}>
      {dragging && (
        <DropArea className="absolute border-dashed border-4 inset-0">
          <DropPlaceholder className="flex-1 text-3xl text-center">
            <span>drop your files here</span>
          </DropPlaceholder>
        </DropArea>
      )}
      {props.children}
    </div>
  );
};
